<!--

      This wizard is displayed before the registration when a user opens the
      app for the first time (or lost their access token for whatever reason).
      The purpose of this wizard is to display foodable's value propositions.

 -->

<template>
  <Page hidelargeheader="true" hideheader="true" :noPaddingTop="true" :containsSlides="true" :noPaddingBottom="true" :scroll="false">
    <Slides class="valueslides" :redirect="redirect" :analyticsEvent="'valueslides'" :progress="'bullets'" :showSkip="true" :nameOfRoute="'valueslides'">
      <Slide :headerHeart="true" :headerLong="true" :featureHeader="true" :title="'Schön, dass du da bist!'">
        <div class="slide-img-area">
          <img class="slide-img" src="../assets/img/foodable_team.jpeg" alt="">
        </div>
        <div class="main-text text-section value-text">
          Wir sind Silas, Daniel und Sarah.<br><br>
          Wir haben foodable für uns selbst entwickelt, damit wir entspannter, günstiger und nachhaltiger einkaufen können. Und wir freuen uns das mit dir zu teilen.
        </div>
        <div class="confetti-container">
          <div v-for="i in 20" :key="i" class="confetti"></div>
        </div>
      </Slide>
      <Slide :headerHeart="true" :featureHeader="true" :title="'Rezepte und Produkte, die zu dir passen'">
        <div class="slide-img-area">
          <img src="../assets/img/individual-prefs.svg" alt="">
        </div>
        <div class="main-text text-section value-text">
          Wir empfehlen dir Rezepte, die genau zu deinen individuellen Ernährungsgewohnheiten passen.
          <br><br>
          Durch unsere künstliche Intelligenz werden diese Empfehlungen immer besser. 
        </div>
      </Slide>
      <Slide :headerHeart="true" :featureHeader="true" :title="'Einfache und flexible Wochenplanung'">
        <img src="../assets/img/tutorial-slide.svg" style="margin-top: 30px;" alt="">
      </Slide>
      <Slide :headerHeart="true" :featureHeader="true" :title="'Werde zum Lebensmittelretter'">
        <div class="slide-img-area">
          <img src="../assets/img/foodwaste.svg" alt="">
        </div>
        <div class="main-text text-section value-text">
          Werde durch unsere intelligente Rezeptzusammenstellung und mit 10 Minuten im Monat zum Lebensmittelretter.
          <br><br>
          Du tust Gutes für die Umwelt und sparst dabei Geld und Zeit!
        </div>
      </Slide>
      <Slide :headerHeart="true" :featureHeader="true" :title="'Kein Schlangestehen und Tüten schleppen'">
        <div class="slide-img-area">
          <img src="../assets/img/mealkit.svg" alt="">
        </div>
        <div class="main-text text-section value-text">
          Alle Zutaten für die Rezepte fügen wir automatisch deinem Warenkorb hinzu.
          <br><br>
          Füge noch andere Produkte deines Lieblingssupermarktes hinzu und bestelle bequem von Zuhause aus.
        </div>
      </Slide>
    </Slides>
  </Page>
</template>

<script>
import Slides from '@/components/slides/Slides.vue'
import Slide from '@/components/slides/Slide.vue'
import Page from '@/components/Page.vue'

export default {
  name: 'valueslides',
  components: {
    Slides,
    Slide,
    Page
  },
  methods: {
    redirect () {
      this.$store.dispatch('goTo', 'signin')
    }
  },
  created () {
    if (!this.$store.state.notificationsEnabled && !this.$store.state.onboarded) {
      this.$store.dispatch('askForNotificationPermission').then(() => {
        this.$store.dispatch('activateRegistrationReminderNotification')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.slide-img {
  border-radius: $border-radius;
  object-fit: cover;
}


</style>
